/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.2.1/dist/jquery.min.js
 * - /npm/popper.js@1.12.9/dist/umd/popper.min.js
 * - /npm/galleria@1.5.7/dist/galleria.min.js
 * - /npm/lightslider@1.1.6/dist/js/lightslider.min.js
 * - /npm/bootstrap@4.0.0-beta.3/dist/js/bootstrap.min.js
 * - /npm/bootstrap-select@1.13.10/dist/js/bootstrap-select.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
